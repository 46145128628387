<template>
  <v-container fluid>
    <v-select
      v-model="event"
      :items="evenementsSelectItems"
      :no-data-text="$t('No results')"
      :label="$t('Evenement')"
      item-text="nom"
      item-value="evenement[nom]"
      chips
      close
      @click:close="chips = false"
    >
    </v-select>
    <v-select
      v-model="categorie"
      :items="animationCategorieSelectItems"
      :no-data-text="$t('No results')"
      :label="$t('Catégorie')"
      item-text="nom"
      item-value="animationCategorie[nom]"
      chips
      multiple
      close
      @click:close="chips = false"
    >
    </v-select>
    <v-select
      v-model="type"
      :items="animationTypeSelectItems"
      :no-data-text="$t('No results')"
      :label="$t('Type')"
      item-text="nom"
      item-value="animationType[nom]"
      chips
      multiple
      close
      @click:close="chips = false"
    >
    </v-select>
  </v-container>
</template>

<script>
import has from "lodash/has";
import isEmpty from "lodash/isEmpty";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "AnimationFilter",
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      event: null,
      categorie: null,
      type: null,
    };
  },
  mounted() {
    this.evenementsGetSelectItems();
    this.animationCategorieGetSelectItems();
    this.animationTypeGetSelectItems();
  },
  watch: {
    event: function() {
      this.$emit("input", { "evenement.nom": this.event });
    },
    categorie: function() {
      this.$emit("input", { "animationCategories.nom": this.categorie });
    },
    type: function() {
      this.$emit("input", { "animationType.nom": this.type });
    },
    value: function() {
      if(isEmpty(this.value)){
        this.event=null;
        this.categorie=null;
        this.type=null;
      }
    }
  },
  computed: {
    ...mapFields("evenement", {
      evenementsSelectItems: "selectItems",
    }),
    ...mapFields("animationCategorie", {
      animationCategorieSelectItems: "selectItems",
    }),
    ...mapFields("animationType", {
      animationTypeSelectItems: "selectItems",
    }),
    // eslint-disable-next-line
    item() {
      let data = this.initialValues || this.value;
      return data;
    },
  },
  methods: {
    ...mapActions({
      evenementsGetSelectItems: "evenement/fetchSelectItems",
      animationCategorieGetSelectItems: "animationCategorie/fetchSelectItems",
      animationTypeGetSelectItems: "animationType/fetchSelectItems",
    }),
  },
};
</script>
