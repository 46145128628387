<template>
  <div class="animation-list">
    <Toolbar :handle-add="addHandler" />

    <v-container grid-list-xl fluid>
      <v-layout row wrap>
        <v-flex sm12>
          <h1>Liste des Animations</h1>
        </v-flex>
        <v-flex lg12>
          <DataFilter :handle-filter="onSendFilter" :handle-reset="resetFilter">
            <AnimationFilterForm
              ref="filterForm"
              v-model="filters"
              slot="filter"
            />
          </DataFilter>

          <br />

          <v-data-table
            v-model="selected"
            :headers="headers"
            :items="items"
            :items-per-page.sync="options.itemsPerPage"
            :loading="isLoading"
            :loading-text="$t('Loading...')"
            :options.sync="options"
            :server-items-length="totalItems"
            class="elevation-1"
            item-key="@id"
            show-select
            @update:options="onUpdateOptions"
            :footer-props="{
              'items-per-page-options': [15, 30, 50, 100],
            }"
          >
            <template slot="item.mediaObjectImage" slot-scope="{ item }" v-if="item.mediaObjectImage">
              <v-img v-if="isImageExtension(item.mediaObjectImage.contentUrl)" contain max-height="75px" max-width="10vw" :src="getFileUrl(item.mediaObjectImage.contentUrl)"/>
              <template v-else>{{ item.contentUrl.toUpperCase() }}</template>
            </template>
            <template slot="item.bgcolor" slot-scope="{ item }">
              <v-alert :color="item.bgcolor">{{item['bgcolor']}}</v-alert>
            </template>
            <template slot="item.dateDebut" slot-scope="{ item }">
              {{ formatDateTime(item["dateDebut"], "long") }}
            </template>
            <template slot="item.dateFin" slot-scope="{ item }">
              {{ formatDateTime(item["dateFin"], "long") }}
            </template>
            <ActionCell
              slot="item.action"
              slot-scope="props"
              :handle-show="() => showHandler(props.item)"
              :handle-edit="() => editHandler(props.item)"
              :handle-delete="() => deleteHandler(props.item)"
            ></ActionCell>
          </v-data-table>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { mapFields } from "vuex-map-fields";
import ListMixin from "../../mixins/ListMixin";
import ActionCell from "../../components/ActionCell";
import AnimationFilterForm from "../../components/animation/Filter";
import DataFilter from "../../components/DataFilter";
import Toolbar from "../../components/Toolbar";
import {isImageExtension} from '../../utils/filesType';
import {ENTRYPOINT} from "../../config/entrypoint";

export default {
  name: "AnimationList",
  servicePrefix: "Animation",
  mixins: [ListMixin],
  components: {
    Toolbar,
    ActionCell,
    AnimationFilterForm,
    DataFilter,
  },
  data() {
    return {
      headers: [
        // { text: 'slug', value: 'slug' },
        { text: "Edition", value: "evenement[nom]" },
        { text: "Nom", value: "nom" },
        { text: "Type d'animation", value: "animationType[nom]" },
        { text: "Horaire de début", value: "dateDebut" },
        { text: "Horaire de fin", value: "dateFin" },
        // { text: 'descriptionCourt', value: 'descriptionCourt' },
        // { text: 'descriptionComplet', value: 'descriptionComplet' },
        // { text: 'visible', value: 'visible' },
        { text: "Lieu", value: "localisation[nom]" },
        { text: "Couleur", value: "bgcolor" },
        // { text: 'motcles', value: 'motcles' },
        { text: "image", value: "mediaObjectImage" },
        {
          text: "Actions",
          value: "action",
          sortable: false,
        },
      ],
      selected: [],
    };
  },
  computed: {
    ...mapGetters("animation", {
      items: "list",
    }),
    ...mapFields("animation", {
      deletedItem: "deleted",
      error: "error",
      isLoading: "isLoading",
      resetList: "resetList",
      totalItems: "totalItems",
      view: "view",
    }),
  },
  methods: {
    ...mapActions("animation", {
      getPage: "fetchAll",
      deleteItem: "del",
    }),
    isImageExtension,
    getFileUrl(path){
      return ENTRYPOINT+path
    }
  },
};
</script>
